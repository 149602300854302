.screen-booking-schedule-detail {


  .card-container .ant-card-body {
    padding: 12px 24px 6px 24px;
  }

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }

  .active-hour {
    background-color: #cee7d1 !important;
    border-color: #cee7d1 !important;
    color: rgba(0, 0, 0, 0.88) !important;

    :active {
      background-color: #cee7d1 !important;
      border-color: #cee7d1 !important;
    }
  }

  .inactive-hour {
    background-color: #f0f0f0;
    color: #000;
  }

  .ant-checkbox-input {
    width: 23px !important;
    height: 23px !important;
  }

  .ant-radio-button-wrapper-checked::before {
    background-color: transparent !important;
  }

  .ant-checkbox-inner {
    width: 23px !important;
    height: 23px !important;
    background-color: #f0f0f0 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #cee7d1 !important;
    border-color: #cee7d1 !important;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    border: 2px solid #000;
    border-top: 0;
    border-inline-start: 0;
    inset-inline-start: 30%;
  }

  .box-btn {

    margin: auto;
    padding-top: 24px;
    background-color: #fff;

    .ant-col-24 {
      display: flex;
      align-items: center;

      button {
        width: 100%;
        height: 40px;
        max-width: 400px;
        margin: auto;
      }
    }
  }

  .txt-total-minutes {
    color: red;
    font-style: italic;
  }

}

.position-bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 42px;
  margin: auto;
  padding: 12px 0px;
  background-color: #fff;

  .ant-col-24 {
    display: flex;
    align-items: center;

    button {
      width: 80%;
      height: 45px;
      margin: auto;
    }
  }
}