.pad-6 {
  padding: 6px 0px;
}

.pad-12 {
  padding: 12px 0px;
}

.pad-24 {
  padding: 24px 0px;
}

.pad-36 {
  padding: 36px 0px;
}

.pad-48 {
  padding: 48px 0px;
}

.pad-top-12 {
  padding-top: 12px;
}