.screen-schedule-timeline {


  padding: 12px;
  font-family: "Arial, sans-serif";

  .header {
    background-color: #FFD700;
    text-align: center;
    padding: 8px;
    font-weight: bold;
  }


  .timeline-container {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    scroll-behavior: smooth;
  }

  .hours-container {
    display: flex;
    min-width: 1440px;
  }

  .hours-no {
    width: 80px;
    padding: 8px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: sticky;
    left: 0px;
    background-color: rgb(71, 69, 69);
    z-index: 1;
  }

  .hours-timeline {
    display: flex;
    flex: 1 1 0%;
  }

  .hour-item {
    flex: 1 1 0%;
    text-align: center;
    padding: 8px;
    background-color: rgb(71, 69, 69);
    color: rgb(255, 255, 255);
    border-left: 1px solid rgb(221, 221, 221);
    border-right: none;
  }

  .hour-item-last {
    flex: 1 1 0%;
    text-align: center;
    padding: 8px;
    background-color: rgb(71, 69, 69);
    color: rgb(255, 255, 255);
    border-left: 1px solid rgb(221, 221, 221);
    border-right: 1px solid rgb(221, 221, 221);
  }

  .bo-container {
    display: flex;
    border: 1px solid rgb(221, 221, 221);
    align-items: center;
    min-width: 1440px;
  }

  .bo-name {
    width: 80px;
    padding: 11px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: sticky;
    left: 0px;
    z-index: 1;
    background-color: rgb(71, 69, 69);
    color: rgb(255, 255, 255);
  }

  .bo-timeline {
    display: flex;
    position: relative;
    flex: 1 1 0%;
    height: 35px;
    background-color: rgb(255, 255, 255);
  }

  .hourly-grid {
    flex: 1 1 0%;
    border-left: 1px solid rgb(221, 221, 221);
    border-right: none;
  }

  .hourly-grid-last {
    flex: 1 1 0%;
    border-left: 1px solid rgb(221, 221, 221);
    border-right: 1px solid rgb(221, 221, 221);
  }

  .schedule-item {
    position: absolute;
    background-color: #5E8BF2;
    color: #fff;
    text-align: center;
    line-height: 35px;
    border-radius: 4px;
    overflow: hidden;
    padding: 0 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }


}